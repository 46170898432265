import React from "react";
import imgFavicon from "../assets/image/jpg/fav.jpg";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import Contact from "../sections/others/Contact";

const ContactPage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Helmet>
        <title>Contact | Nulane New Zealand</title>
          <meta name="keywords" content="Nulane NZ" />
          <link rel="icon" type="image/jpg" href={imgFavicon} />
          <meta name="description" content="Nulane is an Auckland-based team of Web Designers and Developers, that provides cost-effective web development, SEO and Hosting Services." />
          <html lang="en" />
        </Helmet>
        <Box pt={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};

export default ContactPage;
